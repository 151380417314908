 .allPost{
    display: flex;
    flex: 9;
    flex-wrap: wrap;
    gap: 40px;
    padding: 20px;
    justify-content: space-around;

}
.post {
  width: 385px;
  display: flex;
  flex-direction: column;

  }
  
  .postImg {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
  }
  
  .postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .postTitle {
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 900;
    margin-top: 0;
    cursor: pointer;
  }

  .postTitle a {
    color:#007a48;
  }
  
  .postDate {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    margin-top: -15px;
    font-weight: 700;
  }
  
  .postDesc {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #504f6b;
    margin-top: 0;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }