
  .App {
    width: 80%;
    margin: 0 auto;
    margin-top: 100px;
  }
  
  .slick-slide > div {
    margin: 0 10px;
  }



  .slick-list {
    margin: 0 -10px;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    height: 430px;
    color: #007a48;
    cursor: pointer;
  }

  .card:hover{
    
  }
  
  .card-top h1 {
    font-size: 1.5rem;
    margin: 20px;
    font-weight: 900;
  }
  
  .card-top > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-bottom {
    margin: 10px 20px;
  }

  .card-bottom p {
    color:#464e71
  }
  
  .category {
    /* position: relative; */
    color: #007a48;
    font-size: 20px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: left;
    gap:10px
  }

  .category:hover{
    color:#72bf44;
  }
  .category::before {
    content: '';
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    height: 2px;
  }

  .App button{
    display: flex;
    justify-content:center;
    align-items: center;


  }

  /* slick-arrow slick-prev slick-disabled */
  .slick-prev{
  }

  .slick-next{
   }

   .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color:#007a48;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
