
   .link> a{
        position: relative;
        text-decoration: none;
      }
         
     .link> a:after{
        content: "";
        position: absolute;
        background-color: #ebe956;
        height: 3px;
        width: 0;
        left: 0;
        bottom: -10px;
        transition:0.3s;
      }
  
     .link> a:hover::after{
        width: 100%;
      }

      .Class_Services_Navbar{
       color:#007a48;
      }
      
      .custom-autocomplete .MuiAutocomplete-tag {
        background-color: #0a5938; /* Customize the background color */
        color: white; /* Customize the text color */
        font-size: 12px;
      }








      
      
      
      
      
   