.singlePost {
    flex: 9;
    padding: 20px;
  }
  
  .singlePostWrapper {
    padding-right: 0;
  }
  
  .singlePostImg {
    width: 100%;
    height: 350px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .singlePostTitle {
    text-align: center;
    margin: 10px;
    font-size: 28px;
    font-family: "Lora", sans-serif;
    color:#007a48
  }
  
  .singlePostEdit {
    float: right;
    font-size: 16px;
  }
  
  .singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  
  .singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #007a48;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
  }
  
  .singlePostAuthor{
    margin-left: 5px;
  }
  
  .singlePostDesc {
    color: #504f6b;
    font-size: 18px;
    line-height: 25px;
  }
  
  .singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
  }



  .blockcontent img, svg {
      vertical-align: middle;
      width: fit-content;
      max-width: 100%;
  }

  .blockcontent p {
    color: #504f6b;
    font-size: 18px;
    line-height: 25px;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    text-align: justify;
}

/* .blockcontent p::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
} */